import {cva} from 'class-variance-authority';

export const containerClass = cva(
  'flex justify-between w-full gap-2 font-semibold',
  {
    variants: {
      mode: {
        light: '',
        dark: 'bg-shade-80',
      },
    },
  },
);

export const cursorPaginationContainerClass = cva(
  'flex justify-center items-center w-full gap-2 font-semibold',
  {
    variants: {
      mode: {
        light: '',
        dark: 'bg-shade-80',
      },
    },
  },
);

export const paginationButtonClasses = cva(
  'p-2 group font-bold underline hover:no-underline focus:outline-state-focus outline-2 outline-offset-2 transition-colors duration-150 ease-in-out flex items-center',
  {
    variants: {
      mode: {
        light:
          'text-link-light fill-link-light disabled:text-shade-30 disabled:fill-shade-30 hover:text-shade-70 hover:fill-shade-70 active:fill-shade-50 active:text-shade-50 focus:fill-black focus:text-black',
        dark: 'text-link-dark fill-link-dark disabled:text-shade-60 disabled:fill-shade-60 hover:text-shade-30 hover:fill-shade-30 active:fill-white active:text-shade-00 focus:fill-black focus:text-shade-00',
      },
    },
  },
);

export const paginationPageButtonClasses = cva(
  'inline-block h-11 w-11 overflow-hidden whitespace-nowrap py-3.5 leading-none transition-all text-center',
  {
    variants: {
      mode: {
        light: 'text-shade-50 hover:text-shade-70 active:text-shade-90',
        dark: 'text-shade-40 hover:text-shade-30 active:text-white',
      },
    },
  },
);

export const paginationCurrentPageButtonClasses = cva(
  'inline-block h-11 w-11 overflow-hidden whitespace-nowrap py-3.5 text-center leading-none transition-all',
  {
    variants: {
      mode: {
        light:
          'text-link-light border-b-2 border-link-light bg-black bg-opacity-5',
        dark: 'text-link-dark border-b-2 border-link-black bg-white bg-opacity-10',
      },
    },
  },
);

export const disabledLink = cva('p-2 flex items-center', {
  variants: {
    mode: {
      light: 'text-link-light-disabled fill-link-light-disabled',
      dark: 'text-link-dark-disabled fill-link-dark-disabled',
    },
  },
});
